import * as React from "react";
import { useQuery } from "react-query";
import { ClientConfigsService, UserAccountsService } from "../../../gen/clients/llts";
import { LoadingPage } from "../../../components/LoadingPage/LoadingPage";
import { ErrorPage } from "../../../components/ErrorPage/ErrorPage";
import { OrganizationSelector } from "./components/OrganizationSelector";
import { SignedInUserForm } from "./components/SignedInUserForm";
import Container from "@mui/material/Container";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface Props {
  email: string;
}

/**
 * This component is responsible for rendering the create project page for a signed-in user.
 */
const SignedInUserPage: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedClientId, setSelectedClientId] = React.useState<string>();

  // Fetch the user account.
  const {
    data: userAccount,
    isLoading: isUserAccountLoading,
    error: userAccountError
  } = useQuery(["getUserAccount", email], {
    queryFn: UserAccountsService.getUserAccount
  });

  // Fetch the linked clients for the user.
  const {
    data: linkedClients,
    isLoading: areLinkedClientsLoading,
    error: linkedClientsError
  } = useQuery(["listLinkedClients", email], {
    queryFn: UserAccountsService.listLinkedClients
  });

  // Fetch the selected client config.
  const {
    data: selectedClientConfig,
    isLoading: isClientConfigLoading,
    error: clientConfigError
  } = useQuery(["getClientConfig", selectedClientId], {
    enabled: !!selectedClientId,
    queryFn: () => ClientConfigsService.getClientConfig({ clientId: selectedClientId || "" })
  });

  const activeLinkedClients = React.useMemo(() => linkedClients?.filter(c => c.status === "ACTIVE"), [linkedClients]);

  const isLoading = isUserAccountLoading || areLinkedClientsLoading || isClientConfigLoading;
  const error = userAccountError || linkedClientsError || clientConfigError;

  if (error) {
    return <ErrorPage apiError={error} />;
  }

  if (isLoading || !userAccount || !activeLinkedClients) {
    return <LoadingPage />;
  }

  /**
   * Check if the user's client is active.
   */
  if (userAccount?.clientConfig?.status !== "ACTIVE") {
    return (
      <Container maxWidth="md">
        <Alert severity="error" sx={{ mt: 3 }}>
          <span dangerouslySetInnerHTML={{ __html: t("createProject.signedInUser.inactiveClient") }} />
        </Alert>
      </Container>
    );
  }

  // Check if the user has active linked clients.
  if (activeLinkedClients.length > 0) {
    // If a client is selected, render the signed-in user form.
    if (selectedClientConfig) {
      return <SignedInUserForm email={email} clientConfig={selectedClientConfig} />;
    } else {
      // If no client is selected, render the organization selector.
      return (
        <OrganizationSelector
          userAccount={userAccount}
          linkedClients={activeLinkedClients}
          onSelect={setSelectedClientId}
          onCancel={() => history.push("/")}
        />
      );
    }
  }

  // Render the signed-in user form directly if the user does not have active linked clients.
  return <SignedInUserForm email={email} clientConfig={userAccount.clientConfig} />;
};

export { SignedInUserPage };
