import * as React from "react";
import { Form, Formik } from "formik";
import Stack from "@mui/material/Stack";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import Container from "@mui/material/Container";
import { LinkedClient, UserAccount } from "gen/clients/llts";
import { SELECT_OPTION_COMPARATOR } from "utils/stringUtils";
import { SelectOption } from "@mui/base";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface Props {
  userAccount: UserAccount;
  linkedClients: LinkedClient[];
  onSelect: (clientId: string) => void;
  onCancel: () => void;
}

const OrganizationSelector: React.FC<Props> = ({ userAccount, linkedClients, onSelect, onCancel }) => {
  const { t } = useTranslation();
  const organizationOptions: SelectOption<string>[] = [
    { value: userAccount.clientConfig?.clientId || "", label: userAccount.clientConfig?.companyName || "" },
    ...linkedClients.map(c => ({ value: c.clientId, label: c.clientName }))
  ].sort(SELECT_OPTION_COMPARATOR);

  const onSubmit = React.useCallback((values: { organization: string }) => onSelect(values.organization), [onSelect]);

  return (
    <Container maxWidth="md">
      <Formik
        initialValues={{
          organization: userAccount.clientConfig?.clientId || ""
        }}
        onSubmit={onSubmit}
      >
        <Form noValidate={true} autoComplete="off">
          <Stack sx={{ mt: 8 }} spacing={3}>
            <Typography>{t("createProject.signedInUser.organizationSelector.selectOrganization")}</Typography>
            <SelectOneField
              name="organization"
              label={t("createProject.signedInUser.organizationSelector.organization")}
              options={organizationOptions}
              required={true}
              variant="outlined"
            />
            <Stack direction="row" spacing={1} justifyContent="center">
              <Button variant="contained" type="submit" sx={{ width: 140 }}>
                {t("common.ok")}
              </Button>
              <Button onClick={onCancel}>{t("common.cancel")}</Button>
            </Stack>
          </Stack>
        </Form>
      </Formik>
    </Container>
  );
};

export { OrganizationSelector };
