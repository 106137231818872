/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssignClientRequest } from '../models/AssignClientRequest';
import type { CreateUserAccount } from '../models/CreateUserAccount';
import type { EmailConfirmation } from '../models/EmailConfirmation';
import type { LanguageGroup } from '../models/LanguageGroup';
import type { LanguageGroupScope } from '../models/LanguageGroupScope';
import type { LinkedClient } from '../models/LinkedClient';
import type { SendEmailVerificationRequest } from '../models/SendEmailVerificationRequest';
import type { UserAccount } from '../models/UserAccount';
import type { ValidateEmailVerificationCodeRequest } from '../models/ValidateEmailVerificationCodeRequest';
import type { ValidateEmailVerificationCodeResponse } from '../models/ValidateEmailVerificationCodeResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { request as __request } from '../core/request';

export class UserAccountsService {

    /**
     * Fetches user account information for current principal
     * @returns UserAccount A 200 response.
     * @throws ApiError
     */
    public static getUserAccount(): CancelablePromise<UserAccount> {
        return __request({
            method: 'GET',
            path: `/userAccount`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Creates a new user account
     * @returns UserAccount A 200 response.
     * @throws ApiError
     */
    public static creatUserAccount({
        requestBody,
    }: {
        /** New user account payload **/
        requestBody?: CreateUserAccount,
    }): CancelablePromise<UserAccount> {
        return __request({
            method: 'POST',
            path: `/userAccount`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad input parameter`,
                403: `Forbidden`,
            },
        });
    }

    /**
     * Confirms user account's email address
     * @returns void
     * @throws ApiError
     */
    public static confirmEmail({
        requestBody,
    }: {
        /** New user account payload **/
        requestBody?: EmailConfirmation,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/userAccount/actions/confirmEmail`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `confirmation failed`,
            },
        });
    }

    /**
     * Assigns a client to a client contact account
     * @returns void
     * @throws ApiError
     */
    public static assignClient({
        requestBody,
    }: {
        requestBody?: AssignClientRequest,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/userAccount/actions/assignClient`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `invalid parameter`,
            },
        });
    }

    /**
     * Send email verification code request
     * @returns void
     * @throws ApiError
     */
    public static sendEmailVerificationCode({
        requestBody,
    }: {
        requestBody?: SendEmailVerificationRequest,
    }): CancelablePromise<void> {
        return __request({
            method: 'POST',
            path: `/userAccount/actions/sendEmailVerificationCode`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `invalid parameter`,
            },
        });
    }

    /**
     * Validate email verification code
     * @returns ValidateEmailVerificationCodeResponse Successful operation.
     * @throws ApiError
     */
    public static validateEmailVerificationCode({
        requestBody,
    }: {
        requestBody?: ValidateEmailVerificationCodeRequest,
    }): CancelablePromise<ValidateEmailVerificationCodeResponse> {
        return __request({
            method: 'POST',
            path: `/userAccounts/actions/validateEmailVerificationCode`,
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Returns user account's language groups
     * @returns LanguageGroup A successful response.
     * @throws ApiError
     */
    public static listLanguageGroups(): CancelablePromise<Array<LanguageGroup>> {
        return __request({
            method: 'GET',
            path: `/userAccount/languageGroups`,
            errors: {
                400: `bad input parameter`,
            },
        });
    }

    /**
     * Stores language groups belonging to a scope.  Overwrites existing groups.
     * @returns LanguageGroup A successful response.
     * @throws ApiError
     */
    public static storeLanguageGroups({
        groupScope,
        requestBody,
    }: {
        /** Language group scope **/
        groupScope: LanguageGroupScope,
        requestBody?: Array<LanguageGroup>,
    }): CancelablePromise<LanguageGroup> {
        return __request({
            method: 'POST',
            path: `/userAccount/languageGroups/${groupScope}`,
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad input parameter`,
            },
        });
    }

    /**
     * Returns an array of linked clients for a client contact
     * @returns LinkedClient A successful response.
     * @throws ApiError
     */
    public static listLinkedClients(): CancelablePromise<Array<LinkedClient>> {
        return __request({
            method: 'GET',
            path: `/userAccount/linkedClients`,
        });
    }

}